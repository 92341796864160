import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.teal.darken3,
                secondary: colors.grey.darken3,
                accent: colors.shades.black,
                error: colors.pink.darken1,
            },
        },
        options: {
            variations: false,
            themeCache: {
                get: key => localStorage.getItem(key.toString()),
                set: (key, value) => localStorage.setItem(key.toString(), value),
            }
        }
    },
});
