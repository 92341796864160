import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Auth.vue'),
    meta: {
      allowAnonymous: true
    },
  },
  {
    path: '/choice',
    component: () => import('../views/Choice.vue'),
  },
  {
    path: '/session/start',
    component: () => import('../views/StartSession.vue'),
  },
  {
    path: '/session/stop',
    component: () => import('../views/StopSession.vue'),
  },
  {
    path: '/leave/apply',
    component: () => import(/* webpackChunkName: "about" */ '../views/ApplyLeave.vue'),
  },
  {
    path: '/leave/view',
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewLeave.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
import store from '@/store';
router.beforeEach((to, from, next) => {
  if (to.matched.some((route: any) => !route.meta.allowAnonymous) && !store.state.access_token)
  {
    next({path: '/', replace: true});
  } else {
    next();
  }
});
export default router
