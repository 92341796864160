// const nameof = (variable:any) => Object.keys({variable})[0];

const variableNames: string[] = [
    "VUE_APP_I18N_LOCALE",
    "VUE_APP_I18N_FALLBACK_LOCALE",
    "VUE_APP_FIREBASE_API_KEY",
    "VUE_APP_FIREBASE_AUTH_DOMAIN",
    "VUE_APP_FIREBASE_PROJECT_ID",
    "VUE_APP_FIREBASE_STORAGE_BUCKET",
    "VUE_APP_FIREBASE_MESSAGING_SENDER_ID",
    "VUE_APP_FIREBASE_APP_ID",
    "VUE_APP_ENDPOINT_BACKEND",
];

function ensureConfigVarSet(): boolean {
    for(let i=0; i<variableNames.length; i++) {
        if(!process.env[variableNames[i]]) {
            const msg = `Required variable :${variableNames[i]} not set!`;
            console.error(msg);
            alert(msg);
            return false;
        }
    }
    return true;
}

export default ensureConfigVarSet;
