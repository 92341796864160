import ensureConfigVarSet from '@/ConfigChecker';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import {i18n} from './i18n'

import firebaseConfig from '@/FirebaseConfig';
import firebase from 'firebase';
import 'firebaseui/dist/firebaseui.css'
// Add the FirebaseTypes services that you want to use
import "firebase/auth";

Vue.config.productionTip = false

if(ensureConfigVarSet()) {
  firebase.initializeApp(firebaseConfig);

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');
}
