<template>
  <v-app>
    <v-toolbar dark color="info" max-height="64px" v-if="showAppBar()">
      <v-btn color="warning"
             :disabled="!canBack()"
             @click="$router.back()"
      >
        <v-icon>mdi-arrow-left-bold-outline</v-icon>
      </v-btn>
      <v-spacer/>
      <v-avatar>
        <v-img :src="$store.state.user.photoURL"/>
      </v-avatar>
      <v-spacer/>
      <div class="text-center headline">{{ greeting }}</div>
      <v-spacer/>
      <div class="text-center headline">{{timeString}}</div>
    </v-toolbar>
    <v-main>
      <router-view/>
      <v-container v-if="showAppBar()">
        <v-card>
          <v-card-actions>
            <v-btn  block color="warning" @click="onSignOut">Sign Out</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  computed: {
    greeting() {
      return `${this.$t('GREETING', {who: this.user})}`
    },
    timeString() {
      return `${this.$d(this.time, 'long')}`;
    },
  },
  methods: {
    showAppBar() {
      const route = this.$router.currentRoute.fullPath;
      return route !== '/';
    },
    canBack() {
      const route = this.$router.currentRoute.fullPath;
      return route === '/start' || route === '/leave';
    },
    async onSignOut() {
      await this.$store.dispatch('signOut');
    }
  },
  data: () => ({
    user: 'Ferry',
    time: new Date().getTime(),
    handler: null,
  }),
  async created() {
    await this.$store.dispatch('initFirebaseAuth')
  },
  mounted() {
    this.handler = setInterval(()=> {this.time+=1000}, 1000);
  },
  async beforeDestroy() {
    clearInterval(this.handler);
    await this.$store.dispatch('signOut');
  }
});
</script>
<i18n>
{
  "en": {
    "GREETING": "Hello {who}"
  }
}
</i18n>
