import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import FirebaseTypes from 'firebase';
import firebase from "firebase/app";
import Unsubscribe = firebase.Unsubscribe;
import jwtDecode, {JwtPayload} from 'jwt-decode';
import router from '@/router';
import axios from 'axios';

interface Session {
    id: string,
    userId: string,
    type: string,
    details: string,
    start: Date,
    end: Date,
}

interface Leave {
    id: string,
    userId: string,
    type: string,
    start: Date,
    end: Date,
}

const store = new Vuex.Store({
    strict: true,
  state: {
    user: {} as FirebaseTypes.User | null,
    firebaseAuthUnsubscribe: {} as Unsubscribe,
    access_token: "",
    currentSession: {} as Session | null,
    currentLeave: {} as Leave | null,
    initialized: false,
  },
    getters: {
        getCurrentLeave: (state) => state.currentLeave,
        getCurrentSession: (state) => state.currentSession,
        getCurrentUser: (state) => state.user,
    },
  mutations: {
      firebaseSignIn(state, payload: FirebaseTypes.User) {
          state.user = payload;
      },
      backendAuthorization(state, payload: string) {
          const decodedJwt = jwtDecode<JwtPayload>(payload);
          state.access_token = payload;
      },
      unsubscribeHandler(state, payload: Unsubscribe) {
          state.firebaseAuthUnsubscribe = payload;
          state.initialized = true;
      },
      firebaseSignOut(state) {
          state.user = null;
          state.currentLeave = null,
          state.currentSession = null,
          state.firebaseAuthUnsubscribe = {} as Unsubscribe;
      },
      session(state, payload: Session | null) {
          state.currentSession = payload;
      },
      leave(state, payload: Leave | null) {
          state.currentLeave = payload;
      }
  },
  actions: {
    async startSession({state, commit}, payload: StartSessionModel) {
        const startResult = await startSession(payload);
        if (startResult.status === 200 && startResult.data) {
            commit('session', startResult.data);
            return true;
        } else {
            console.error(`Error occurred when attempt to start a session. Code: ${startResult.status} Message: ${startResult.statusText}`);
            return false;
        }
    },
      async stopSession({state, commit}) {
        const stopResult = await stopSession({
          sessionId: state.currentSession?.id,
        })
        if(stopResult.status === 200) {
            commit('session', null);
            return true;
        } else {
            console.error(`Error occurred when attempt to stop a session. Code: ${stopResult.status} Message: ${stopResult.statusText}`);
            return false;
        }
      },
    async applyLeave({state, commit}, payload) {
        const applyLeaveModel = {
            start: payload.dates[0],
            end: payload.dates[1],
            type: payload.type,
        }
        const applyLeaveResult = await applyLeave(applyLeaveModel);
        if(applyLeaveResult.status === 200 && applyLeaveResult.data) {
            commit('leave', applyLeaveResult.data);
            return true;
        } else {
            console.error(`Error occurred when attempt to apply leave. Code: ${applyLeaveResult.status} Message: ${applyLeaveResult.statusText}`);
            return false;
        }
    },
    initFirebaseAuth({state, commit}): void {
      const unsubscribe = firebase.auth()
        .onAuthStateChanged(async user => {
        let path = '/';
        if(user) {
            commit('firebaseSignIn', toLocalUser(user));
            try {
                const id_token = await user.getIdToken();
                const authByIdTokenResult: any = await authByIdToken(id_token);

                if (authByIdTokenResult.status === 200) {
                    commit('backendAuthorization', authByIdTokenResult.data.accessToken);

                    if (authByIdTokenResult.data?.newUser && authByIdTokenResult.data?.requireMFA) {
                        path = '/second-auth';
                    } else if (authByIdTokenResult.data?.session) {
                        commit('session', authByIdTokenResult.data.session);
                        path = '/session/stop';
                    } else if (authByIdTokenResult.data?.leave) {
                        commit('leave', authByIdTokenResult.data.leave);
                        path = '/leave/view'
                    }
                    else {
                        path = '/choice'
                    }
                }
            } catch (e) {
                console.error('Error in onUserAuth, redirect back to /', e);
                path = '/';
            }
        } else {
            commit('firebaseSignIn', null);
        }

        if(router.currentRoute.fullPath !== path)
            await router.replace(path);

        }, handleError);
      commit('unsubscribeHandler', unsubscribe);
    },
    async signOut({state, commit}): Promise<void> {
      if(state.user) {
          await firebase.auth().signOut();
        state.firebaseAuthUnsubscribe();
        commit('firebaseSignOut');
      }
    },
  },
})
export default store;

function toLocalUser(user: firebase.User): any {
    return {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous,
        metadata: {
            creationTime: user.metadata.creationTime,
            lastSignInTime: user.metadata.lastSignInTime,
        },
        multiFactor: {
            enrolledFactors: [...user.multiFactor.enrolledFactors],
        },
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        providerData: [...user.providerData],
        refreshToken: user.refreshToken,
        tenantId: user.tenantId,
        uid: user.uid,
    }
}

const handleError = (error: firebase.auth.Error):any => console.error('error', error);

interface StartSessionModel {
    type: string,
    details: string,
}
async function startSession(model: StartSessionModel) {
    return await axios.post(process.env.VUE_APP_ENDPOINT_BACKEND + "session/start",
        model, getAuthorizedConfigObj());
}
interface StopSessionModel {
    sessionId: string | undefined
}
async function stopSession(model: StopSessionModel) {
    return await axios.post(process.env.VUE_APP_ENDPOINT_BACKEND + "session/stop",
        model, getAuthorizedConfigObj());
}

interface ApplyLeaveModel {
    start: string,
    end: string,
    type: string,
}
async function applyLeave(model: ApplyLeaveModel) {
    let conf = getAuthorizedConfigObj();
    return await axios.post(process.env.VUE_APP_ENDPOINT_BACKEND + "leave",
        model, getAuthorizedConfigObj())
}

async function authByIdToken(id_token: string) {
    return await axios.post(process.env.VUE_APP_ENDPOINT_BACKEND + "auth",
        {Token: id_token}, getAnonymousConfigObj());
}

function getAnonymousConfigObj() {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}

function getAuthorizedConfigObj() {
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${store.state.access_token}`,
    }
  }
}
